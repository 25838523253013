import React, { useContext, useEffect, useState } from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import queryString from 'query-string';
import { alphabetize } from 'utils';

const talentsQuery = graphql`
  query TalentsQuery {
    allDatoCmsJobRoleCategory {
      edges {
        node {
          label
          icon
          theHubRoles {
            queryId
          }
        }
      }
    }
    allDatoCmsAreasOfInterest {
      edges {
        node {
          label
          urls {
            label
            href
            urlImage {
              url(imgixParams: { crop: "fit", h: "250", w: "250", fm: "webp" })
              blurhash
            }
          }
        }
      }
    }
    allDatoCmsTechTalent(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          title
          ribbonCaption
          highlightedQuote
          expert {
            name
            jobPosition
            jobRoleCategory {
              label
            }
            company {
              name
              website
            }
            homeCountry {
              country
              continent {
                label
              }
              isoStandardName
              flag {
                fixed(width: 60) {
                  src
                }
              }
            }
            featuredMedia: techTalentImage {
              gatsbyImageData(width: 410, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

export const FilterContext = React.createContext();

const FilterProvider = ({ location, children }) => {
  const {
    allDatoCmsJobRoleCategory: { edges: jobCategories },
    allDatoCmsTechTalent: { edges: techTalents },
    allDatoCmsAreasOfInterest: { edges: areas },
  } = useStaticQuery(talentsQuery);
  const categories = jobCategories
    .map(category => category.node)
    .sort((a, b) => alphabetize(a, b, 'label'));
  const talents = techTalents.map(talent => talent.node);
  const interests = areas.map(area => area.node);
  const { search } = location || {};

  const [activeFilters, setActiveFilters] = useState([]);
  const [roleFilters, setRoleFilters] = useState([]);
  const [filteredTalents, setFilteredTalents] = useState([]);
  const [usersCountry, setUsersCountry] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [jobsCount, setJobsCount] = useState();
  const [areasOfInterest, setAreasOfInterest] = useState([]);

  useEffect(() => {
    if (!usersCountry) {
      fetch(
        `https://api.ipapi.com/api/check?access_key=${process.env.GATSBY_IP_API_KEY}&fields=continent_name,country_code,country_name`
      )
        .then(res => res.json())
        .then(result => {
          if (result.success !== false) setUsersCountry(result);
        });
    }
  }, [usersCountry]);

  useEffect(() => {
    const { interests: paramInterests, roles: paramRoles } =
      (search && queryString.parse(search)) || {};
    const arrayInterests = Array.isArray(paramInterests)
      ? paramInterests
      : paramInterests && [paramInterests];
    const arrayRoles = Array.isArray(paramRoles) ? paramRoles : paramRoles && [paramRoles];
    const defaultInterests = [];
    const defaultRoles = [];

    if (arrayInterests?.length) {
      defaultInterests.push(...arrayInterests.map(int => interests.find(c => c.label === int)));
    }

    if (arrayRoles?.length) {
      defaultRoles.push(...arrayRoles.map(role => categories.find(c => c.label === role)));
    }

    if (defaultInterests?.length) {
      setAreasOfInterest(defaultInterests);
    }

    if (defaultRoles?.length) {
      setRoleFilters(defaultRoles);
    }
  }, [search]);

  useEffect(() => {
    const filterLabels = activeFilters.map(filter => filter.label);

    const filtered = talents.filter(talent =>
      talent.expert.jobRoleCategory.some(role => filterLabels.includes(role.label))
    );

    setFilteredTalents(filtered);
  }, [activeFilters]);

  useEffect(() => {
    if (usersCountry?.country_name && !selectedCountry)
      setSelectedCountry(usersCountry?.country_name);
  }, [usersCountry]);

  const handleFilterAreas = area => {
    const arr = [...areasOfInterest];
    arr.includes(area) ? arr.splice(arr.indexOf(area), 1) : arr.push(area);
    setAreasOfInterest(arr);
  };

  const handleFilter = (from, category) => {
    const isMainFilter = from === 'mainfilter';
    let arr = isMainFilter ? [...activeFilters] : [...roleFilters];
    if (category === 'Show All') {
      arr = [];
    } else {
      arr.includes(category) ? arr.splice(arr.indexOf(category), 1) : arr.push(category);
    }
    if (isMainFilter) setActiveFilters(arr);
    setRoleFilters(arr);

    console.log(from, category);
  };

  const store = {
    activeFilters,
    roleFilters,
    areasOfInterest,
    handleFilterAreas,
    selectedCountry,
    setSelectedCountry,
    categories,
    interests,
    jobsCount,
    setJobsCount,
    handleFilter,
    usersCountry,
    allTalents: talents,
    talents: filteredTalents.length > 0 ? filteredTalents : talents,
  };

  return <FilterContext.Provider value={store}>{children}</FilterContext.Provider>;
};

export const useFilterContext = () => useContext(FilterContext);

export default FilterProvider;
