import 'intersection-observer';
import './src/styles/index.css';
import React from 'react';
import TagManager from 'react-gtm-module';
import { Location } from '@reach/router';
// import NewsletterPopup from 'modules/NewsletterPopup';
import FilterProvider from 'contexts/filter';

console.log('Howdy fellow nerd, hope you are having a mighty fine day! 🙌 🚀 🎉');

TagManager.initialize({
  gtmId: 'GTM-WXHNSDL',
});

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <Location>
    {({ location }) => (
      <FilterProvider location={location}>
        {element}
        {/* <NewsletterPopup /> */}
      </FilterProvider>
    )}
  </Location>
);
